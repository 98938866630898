// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/AboutUs/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-our-plants-js": () => import("./../../../src/pages/AboutUs/OurPlants.js" /* webpackChunkName: "component---src-pages-about-us-our-plants-js" */),
  "component---src-pages-collaboration-custom-products-js": () => import("./../../../src/pages/Collaboration/CustomProducts.js" /* webpackChunkName: "component---src-pages-collaboration-custom-products-js" */),
  "component---src-pages-collaboration-finished-products-js": () => import("./../../../src/pages/Collaboration/FinishedProducts.js" /* webpackChunkName: "component---src-pages-collaboration-finished-products-js" */),
  "component---src-pages-collaboration-index-js": () => import("./../../../src/pages/Collaboration/index.js" /* webpackChunkName: "component---src-pages-collaboration-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/Contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-for-employees-career-js": () => import("./../../../src/pages/ForEmployees/Career.js" /* webpackChunkName: "component---src-pages-for-employees-career-js" */),
  "component---src-pages-for-employees-cloud-js": () => import("./../../../src/pages/ForEmployees/Cloud.js" /* webpackChunkName: "component---src-pages-for-employees-cloud-js" */),
  "component---src-pages-for-employees-e-pracownik-js": () => import("./../../../src/pages/ForEmployees/EPracownik.js" /* webpackChunkName: "component---src-pages-for-employees-e-pracownik-js" */),
  "component---src-pages-for-employees-employee-zone-js": () => import("./../../../src/pages/ForEmployees/EmployeeZone.js" /* webpackChunkName: "component---src-pages-for-employees-employee-zone-js" */),
  "component---src-pages-index-1-js": () => import("./../../../src/pages/index1.js" /* webpackChunkName: "component---src-pages-index-1-js" */),
  "component---src-pages-index-3-js": () => import("./../../../src/pages/index3.js" /* webpackChunkName: "component---src-pages-index-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indextext-js": () => import("./../../../src/pages/indextext.js" /* webpackChunkName: "component---src-pages-indextext-js" */),
  "component---src-pages-our-coffees-coffee-beans-js": () => import("./../../../src/pages/OurCoffees/CoffeeBeans.js" /* webpackChunkName: "component---src-pages-our-coffees-coffee-beans-js" */),
  "component---src-pages-our-coffees-ground-coffee-js": () => import("./../../../src/pages/OurCoffees/GroundCoffee.js" /* webpackChunkName: "component---src-pages-our-coffees-ground-coffee-js" */),
  "component---src-pages-our-coffees-index-js": () => import("./../../../src/pages/OurCoffees/index.js" /* webpackChunkName: "component---src-pages-our-coffees-index-js" */),
  "component---src-pages-our-coffees-instant-coffee-js": () => import("./../../../src/pages/OurCoffees/InstantCoffee.js" /* webpackChunkName: "component---src-pages-our-coffees-instant-coffee-js" */),
  "component---src-pages-our-packaging-design-js": () => import("./../../../src/pages/OurPackaging/Design.js" /* webpackChunkName: "component---src-pages-our-packaging-design-js" */),
  "component---src-pages-our-packaging-packaging-capsules-js": () => import("./../../../src/pages/OurPackaging/Packaging/Capsules.js" /* webpackChunkName: "component---src-pages-our-packaging-packaging-capsules-js" */),
  "component---src-pages-our-packaging-packaging-ground-packaging-js": () => import("./../../../src/pages/OurPackaging/Packaging/GroundPackaging.js" /* webpackChunkName: "component---src-pages-our-packaging-packaging-ground-packaging-js" */),
  "component---src-pages-our-packaging-packaging-index-js": () => import("./../../../src/pages/OurPackaging/Packaging/index.js" /* webpackChunkName: "component---src-pages-our-packaging-packaging-index-js" */),
  "component---src-pages-our-packaging-packaging-instant-packaging-js": () => import("./../../../src/pages/OurPackaging/Packaging/InstantPackaging.js" /* webpackChunkName: "component---src-pages-our-packaging-packaging-instant-packaging-js" */),
  "component---src-pages-our-packaging-packaging-whole-bean-packaging-js": () => import("./../../../src/pages/OurPackaging/Packaging/WholeBeanPackaging.js" /* webpackChunkName: "component---src-pages-our-packaging-packaging-whole-bean-packaging-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/PrivacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quality-index-js": () => import("./../../../src/pages/Quality/index.js" /* webpackChunkName: "component---src-pages-quality-index-js" */),
  "component---src-pages-ue-index-js": () => import("./../../../src/pages/UE/index.js" /* webpackChunkName: "component---src-pages-ue-index-js" */)
}

